/* eslint-disable prefer-arrow-callback */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isArray, isObject } from 'lodash';
import { RulesContext } from '../contexts';
import { Toggle } from '../Toggle';
import { Switch } from '../Switch';
import { Modal } from '../Modal';
import { useDisplay, useCompactView } from '../hooks';
import MemoizedEditor from '../Editor/Editor';
import { schemaContext } from '../../store/schema';

import stripEmptyNestedObjects from '../../utils/strip-empty-nested-objects';

import { mainDataContext } from '../../store/mainData';
import { mainNavigationContext } from '../../store/mainNavigation';
import { modalContext } from '../../store/modal';
import modalReducers from '../../store/modal/modal-reducers';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import { spinnerContext } from '../../store/spinner';
import { saveQueueContext } from '../../store/saveQueue';
import reducers from '../../store/saveQueue/save-queue-reducers';
import generateUiSchema from '../Editor/generateUiSchema';
import { Box } from '@mui/material';

function TableBasedFormContainer({ dataKey }) {
  const { compactView, setCompactView } = useCompactView();
  const [schema] = useContext(schemaContext);
  const [mainData] = useContext(mainDataContext);
  const { definitions, properties } = schema;
  const { getDisplayComponent } = useDisplay(mainData, schema, [dataKey]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [editorType, setEditorType] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [modalVisible] = useContext(modalContext);
  const modalDispatch = useContext(modalContext)[1];
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const spinnerDispatch = useContext(spinnerContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);

  const cleanDataObj = (id, mainDataToClean) => {
    function cleanNestedData(obj) {
      if (obj !== undefined) {
        Object.keys(obj).forEach((key) => {
          if (Array.isArray(obj[key])) {
            obj[key] = obj[key].map((el) => {
              if (!isObject(el) && typeof el === 'string') {
                return el.trim();
              }
              return cleanNestedData(el);
            });
          }
        });
      }
      return obj;
    }
    if (isArray(mainDataToClean[dataKey])) {
      return [cleanNestedData(mainDataToClean[dataKey].find((obj) => obj.id === id))];
    }
    if (isObject(mainDataToClean[dataKey])) {
      return mainDataToClean[dataKey];
    }

    return mainDataToClean[dataKey]; // TODO change with next update
  };

  const handleSubmit = (formData, rowChange) => {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: stripEmptyNestedObjects(formData),
        rowChange,
      },
    });
  };

  useEffect(() => {
    setSelectedData({ [dataKey]: cleanDataObj(selectedRowId, mainData) });
  }, [mainData, schema, selectedRowId]);

  const handleEditRow = (id) => {
    setEditorType('edit');
    setSelectedRowId(id);
    setSelectedData({ [dataKey]: cleanDataObj(id, mainData) });
    setShowModal(true);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: true },
    });
  };

  const handleAddRow = () => {
    setEditorType('add');
    setShowModal(true);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: true },
    });
  };

  const handleDeleteRow = (rowIndex) => {
    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });

    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: mainData,
        rowChange: { dataKey, type: 'delete', rowIndex },
      },
    });
  };

  const handleDuplicateRow = (rowIndex) => {
    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });

    saveQueueDispatch({
      type: reducers.DUPLICATE,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: mainData,
        rowChange: { dataKey, type: 'duplicate', rowIndex },
      },
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedData(null);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: false },
    });
  };

  function editSelectedData() {
    const classNames = compactView ? 'editor--compact editor--modal' : 'editor--modal';

    const uiSchemaForSelected = generateUiSchema(schema, dataKey);

    return (
      <Box>
        <MemoizedEditor
          className={`${classNames} ${dataKey}-editor`}
          selectedSchema={{ definitions, properties }}
          initialData={selectedData}
          uiSchema={uiSchemaForSelected}
          formContext={{ selectedData, path: [] }}
          rowChange={{ dataKey, type: 'editRow', rowIndex: selectedRowId }}
          handleSubmitForm={handleSubmit}
        />
      </Box>
    );
  }

  function createNewData() {
    const classNames = compactView ? 'editor--compact editor--modal' : 'editor--modal';
    const uiSchemaForSelected = generateUiSchema(schema, dataKey);
    const emptyData = {};
    emptyData[dataKey] = [{}];
    return (
      <Box>
        <MemoizedEditor
          className={`${classNames} ${dataKey}-editor`}
          selectedSchema={{ definitions, properties }}
          initialData={emptyData}
          uiSchema={uiSchemaForSelected}
          formContext={{ selectedData, path: [] }}
          rowChange={{ dataKey, type: 'add', rowIndex: null }}
          handleSubmitForm={handleSubmit}
        />
      </Box>
    );
  }

  return (
    <RulesContext.Provider
      value={{
        editRow: handleEditRow,
        addRow: handleAddRow,
        deleteRow: handleDeleteRow,
        copyRow: handleDuplicateRow,
      }}
    >
      {modalVisible.show && (
        <Modal
          openModal={showModal}
          closeModal={handleModalClose}
          className="modal--editor"
        >
          <Box className="modal__editor--header">
            <Toggle
              className="toggle--right toggle__label--before"
              toggle={setCompactView}
              isOn={!compactView}
              label="Show details"
              labelFirst={false}
            >
              <Switch />
            </Toggle>
          </Box>
          {editorType === 'edit' ? editSelectedData() : createNewData()}
        </Modal>
      )}
      <Box className="app__content container-fluid">
        <Box className="content--full-width">{getDisplayComponent()}</Box>
      </Box>
    </RulesContext.Provider>
  );
}

TableBasedFormContainer.propTypes = {
  dataKey: PropTypes.string.isRequired,
};

export default TableBasedFormContainer;
