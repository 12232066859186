import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import ReactPortal from '../Portal';

function ModalContainer({
  openModal,
  closeModal,
  title,
  className,
  footerContent,
  children,
}) {
  const [isOpen, setIsOpen] = useState(openModal);

  const handleClose = () => {
    closeModal();
    setIsOpen(false);
  };

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <Modal
        className={className}
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
        footerContent={footerContent}
      >
        {children}
      </Modal>
    </ReactPortal>
  );
}

ModalContainer.defaultProps = {
  title: null,
  className: null,
  footerContent: null,
};

ModalContainer.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  footerContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default ModalContainer;
