import React, { useEffect } from 'react';
import { isPlainObject, isArray, get } from 'lodash';
import useSchemaPath from './use-schema-path';
import useSchemaProperties from './use-schema-properties';
import { MaterialTableComponent } from '../TableMaterial/material-table';
import { DataItem } from '../DataItem';

function useDisplay(data, schemaRoot, selectedPath) {
  const { getSchemaTitle } = useSchemaProperties(schemaRoot);
  const { setPathToSchema, getSchemaPath } = useSchemaPath();
  const { type } = schemaRoot;

  useEffect(() => {
    setPathToSchema(selectedPath);
  }, []); // eslint-disable-line

  function formatArrayItem(currData, currSchemaPath) {
    const schemaData = get(schemaRoot.properties, currSchemaPath);
    const schemaProps = schemaData.items.properties;
    const itemBlueprint = Object.keys(schemaProps).reduce(
      (acc, key) => ({ ...acc, [key]: '' }),
      {}
    );
    return currData.map((item) => ({ ...itemBlueprint, ...item }));
  }

  function getArrayDisplay(selectedData, path = selectedPath) {
    const currSchemaPath = getSchemaPath(path);

    let currSchemaRoot;

    if (type === 'array') {
      currSchemaRoot = schemaRoot.items.properties;
    } else if (
      get(schemaRoot.properties, currSchemaPath).items.properties === undefined
    ) {
      currSchemaRoot = schemaRoot.properties;
    } else {
      currSchemaRoot = get(schemaRoot.properties, currSchemaPath).items.properties;
    }

    const label = getSchemaTitle(path);

    const isArrayOfObjects =
      selectedData.filter((item) => !isPlainObject(item)).length <= 0;

    const formatArrayString = (formatData) =>
      formatData.map((item) => ({ topLevelDomains: [item] }));

    const formattedData = isArrayOfObjects
      ? formatArrayItem(selectedData, currSchemaPath)
      : formatArrayString(selectedData);
    return (
      <>
        <MaterialTableComponent
          formattedData={formattedData}
          label={label}
          dataKey={path[0]}
          schemaRoot={currSchemaRoot}
        />
      </>
    );
  }

  function getObjectDisplay(selectedData, path = selectedPath) {
    const currSchemaPath = getSchemaPath(path);
    let currSchemaRoot;

    if (type === 'array') {
      currSchemaRoot = schemaRoot.items.properties;
    } else if (get(schemaRoot.properties, currSchemaPath).properties === undefined) {
      currSchemaRoot = schemaRoot.properties; // get(schemaRoot.properties);
    } else {
      currSchemaRoot = get(schemaRoot.properties, currSchemaPath).properties;
    }
    const label = getSchemaTitle(path);
    const formattedData = [selectedData];
    return (
      <>
        <MaterialTableComponent
          formattedData={formattedData}
          label={label}
          dataKey={path[0]}
          schemaRoot={currSchemaRoot}
        />
      </>
    );
  }

  function displaySelected(path = selectedPath) {
    const selectedData = get(data, path);
    const label = getSchemaTitle(path);
    if (selectedData !== undefined) {
      if (isArray(selectedData)) {
        return getArrayDisplay(selectedData, path);
      }

      if (isPlainObject(selectedData)) {
        return getObjectDisplay(selectedData, path);
      }

      return <DataItem label={label} value={selectedData} />;
    }
    return '';
  }

  function getComponent() {
    return displaySelected();
  }

  return { getDisplayComponent: getComponent };
}

export default useDisplay;
