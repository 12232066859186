/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import NumberField from '@rjsf/core/lib/components/fields/NumberField';
import { Box, Typography } from '@mui/material';

function CustomArrayField(props) {
  if (props.name === 'id') {
    return <Typography variant="h5">ID: #{props.formData}</Typography>;
  }

  return (
    <Box className="number-field-widget">
      <NumberField {...props} />
    </Box>
  );
}

CustomArrayField.defaultProps = {
  formData: [],
};

CustomArrayField.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: PropTypes.any,
  schema: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default CustomArrayField;
