import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import './Modal.scss';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../styles/theme';

function Modal({ isOpen, onClose, className, footerContent, children }) {
  const classes = useStyles();

  if (isOpen) {
    return (
      <Box className="modal__overlay">
        <Box className={['modal__modal', className].join(' ')}>
          <IconButton className={classes.closeButton} color="primary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Box className="modal__content">{children}</Box>
          {footerContent && <Box className="modal__footer">{footerContent}</Box>}
        </Box>
      </Box>
    );
  }

  return null;
}

Modal.defaultProps = {
  className: null,
  footerContent: null,
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  footerContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Modal;

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 5,
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
});
