import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import './Tabs.scss';
import { Box, IconButton } from '@mui/material';

function Tabs({ tabs, onClick, onDelete, onCreate, isActive = '*', children }) {
  function createTabs() {
    tabs.sort();
    return (
      <Box className="tabs__list">
        {tabs.map((tab, index) => (
          <Box key={tab} className={isActive === tab ? 'tab active' : 'tab'}>
            <button onClick={(ev) => onClick(ev, tab)}>{tab}</button>
            {index > 0 && (
              <Box
                tabIndex={index}
                className="tab__delete"
                onClick={(ev) => onDelete(ev, tab)}
                onKeyPress={(ev) => onDelete(ev, tab)}
                role="button"
              >
                <CloseIcon />
              </Box>
            )}
          </Box>
        ))}
        <IconButton onClick={onCreate} size="medium" title="Create new tab">
          <AddIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box className="tabs">
      {createTabs()}
      <Box className="tabs__content">{children}</Box>
    </Box>
  );
}

Tabs.defaultProps = {
  children: null,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isActive: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Tabs;
