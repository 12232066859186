import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'react-oauth2-pkce';
import { Box, Typography } from '@mui/material';
import { FormItem } from '../Form-item';
import { Select } from '../Select';

import { getNavigationData } from '../../services/flight-search-service';
import { mainNavigationContext, reducers } from '../../store/mainNavigation';
import './index.css';

function MainNavigation() {
  const { authService } = useAuth();
  const authToken = authService.getAuthTokens();
  const logout = async () => authService.logout();
  const dispatch = useContext(mainNavigationContext)[1];
  const history = useHistory();
  const [navigationData, setNavigationData] = useState([]);
  const [navigationOptions, setNavigationOptions] = useState([]);
  const [selectedNavigationOption, setSelectedNavigationOption] = useState({
    key: '',
    value: '',
  });

  const historyChangeHandler = useCallback(
    async (location) => {
      if (navigationOptions.length === 0) return;

      const selectedNavigationData = navigationData.find(
        (x) => `/${x.pathname}` === location.pathname
      );
      dispatch({ type: reducers.SET_SELECTED, payload: selectedNavigationData });
      setSelectedNavigationOption(
        navigationOptions.find((x) => `/${x.value}` === location.pathname)
      );
    },
    [navigationData, navigationOptions, dispatch]
  );

  const handleOnChange = useCallback(
    (selectedOption) => {
      history.push(selectedOption.value);
    },
    [history]
  );

  useEffect(() => {
    const initialize = async () => {
      // API CALL
      const tokenExpired = new Date() > new Date(authToken.expires_at);
      if (tokenExpired) {
        setTimeout(() => {
          logout();
        }, 1);
      } else {
        const rs = await getNavigationData(authToken);
        setNavigationData(rs);
        setNavigationOptions(rs.toSelect());
      }
    };

    initialize();
  }, []);

  useEffect(
    () => history.listen(historyChangeHandler),
    [history, historyChangeHandler, navigationOptions]
  );

  useEffect(() => {
    if (window.location.pathname === '/' && navigationOptions.length > 0) {
      handleOnChange(navigationOptions[0]);
    } else {
      historyChangeHandler(window.location);
    }
  }, [handleOnChange, historyChangeHandler, navigationOptions]);

  return (
    <Box>
      <FormItem className="main-navigation__select container-fluid" label="">
        <Typography variant="h3">Configurations</Typography>
        <Select
          value={{ ...selectedNavigationOption }}
          className="form-item__select"
          options={navigationOptions}
          name="top-navigation"
          onChange={handleOnChange}
        />
      </FormItem>
    </Box>
  );
}

export default MainNavigation;
