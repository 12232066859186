import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { saveQueueContext } from '../../store/saveQueue';
import { mainNavigationContext } from '../../store/mainNavigation';
import stripEmptyNestedObjects from '../../utils/strip-empty-nested-objects';
import reducers from '../../store/saveQueue/save-queue-reducers';

import { useCompactView } from '../hooks';
import MemoizedEditor from '../Editor/Editor';
import { Toggle } from '../Toggle';
import { Switch } from '../Switch';
import uiSchema from '../Editor/ui-schema';

import { schemaContext } from '../../store/schema';
import { mainDataContext } from '../../store/mainData';
import { Box } from '@mui/material';

function CleanFormContainer({ dataKey }) {
  const { compactView, setCompactView } = useCompactView();
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);
  const [schema] = useContext(schemaContext);
  const [mainData] = useContext(mainDataContext);

  const handleSubmit = (formData, rowChange) => {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: stripEmptyNestedObjects(formData),
        rowChange,
      },
    });
  };

  return (
    <Box className="app__content container-fluid">
      <Box className="content--full-width">
        <Toggle
          className="toggle--left"
          toggle={setCompactView}
          isOn={compactView}
          label="compact view"
        >
          <Switch />
        </Toggle>
        <MemoizedEditor
          className={`clean-form ${
            compactView ? 'editor--compact' : null
          } ${dataKey}-editor`}
          selectedSchema={schema}
          uiSchema={uiSchema}
          formContext={{ mainData, path: '' }}
          rowChange={{ dataKey, type: 'editRule', rowIndex: null }}
          initialData={mainData}
          handleSubmitForm={handleSubmit}
        />
      </Box>
    </Box>
  );
}

CleanFormContainer.propTypes = {
  dataKey: PropTypes.string.isRequired,
};

export default CleanFormContainer;
