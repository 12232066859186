import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { Box } from '@mui/material';

function ToggleContainer({ className, label, labelFirst, toggle, isOn, children }) {
  const [isChecked, setIsChecked] = useState(isOn);
  const classNames = className ? `toggle ${className}` : 'toggle';

  const handleChange = () => {
    setIsChecked(!isChecked);
    toggle();
  };

  return (
    <Box className={classNames}>
      {labelFirst && label && (
        <span className="toggle__label toggle__label--before">{label}</span>
      )}
      {React.cloneElement(children, { checked: isChecked, handleChange })}
      {!labelFirst && label && (
        <span className="toggle__label toggle__label--after">{label}</span>
      )}
    </Box>
  );
}

ToggleContainer.defaultProps = {
  className: null,
  label: null,
  labelFirst: true,
  isOn: false,
};

ToggleContainer.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelFirst: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  isOn: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ToggleContainer;
