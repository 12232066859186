import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@mui/material';
import Tabs from './Tabs';
import { Modal } from '../Modal';
import { Select } from '../Select';

const countryList = [
  { value: 'NO', label: 'Norway' },
  { value: 'SE', label: 'Sweden' },
  { value: 'DA', label: 'Denmark' },
  { value: 'NL', label: 'Nederlands' },
  { value: 'BE', label: 'Belgium' },
  { value: 'FI', label: 'Finland' },
  { value: 'IS', label: 'Iceland' },
];

function TabsContainer({ tabs, onClick, displayComponent }) {
  const [selectedTab, setSelectedTab] = useState('*');
  const [tabToDelete, setTabToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [tabToAdd, setTabToAdd] = useState(null);
  const [countriesToAdd, setCountriesToAdd] = useState([]);

  useEffect(() => {
    const countries = countryList.filter((country) => !tabs.includes(country.value));
    setCountriesToAdd(countries);
  }, [tabs]);

  const handleClick = (ev, tab) => {
    ev.preventDefault();
    setSelectedTab(tab);
    onClick(tab);
  };

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setTabToAdd(null);
  };

  const handleCreateTab = () => {
    setShowCreateModal(false);
    // TODO: add country as property to data root
  };

  const handleDelete = (ev, tab) => {
    ev.preventDefault();
    setTabToDelete(tab);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setTabToDelete(null);
  };

  const handleDeleteTab = () => {
    setShowDeleteModal(false);
    // TODO: remove country settings from data
  };

  const handleSelect = (tab) => {
    setTabToAdd(tab);
  };
  function getCreateModalFooter() {
    return (
      <Box className="button__group">
        <Button variant="contained" onClick={handleCreateTab} sx={{ marginRight: 2 }}>
          Create tab
        </Button>
        <Button variant="outlined" onClick={handleCreateModalClose}>
          Cancel
        </Button>
      </Box>
    );
  }

  function getDeleteModalFooter() {
    return (
      <Box className="button__group">
        <Button variant="contained" onClick={handleDeleteTab} sx={{ marginRight: 2 }}>
          Yes, delete!
        </Button>
        <Button variant="outlined" onClick={handleDeleteModalClose}>
          Cancel
        </Button>
      </Box>
    );
  }

  return (
    <Tabs
      tabs={tabs}
      onClick={handleClick}
      onCreate={handleCreate}
      onDelete={handleDelete}
      isActive={selectedTab}
    >
      {displayComponent}
      {showCreateModal && (
        <Modal
          title="Add new country"
          openModal
          closeModal={handleCreateModalClose}
          footerContent={getCreateModalFooter()}
        >
          <Typography variant="h3" sx={{ marginBottom: 3 }}>
            Please select a country you want to add specific settings to
          </Typography>
          <Select
            className=""
            options={countriesToAdd}
            onChange={handleSelect}
            value={tabToAdd}
            name="addCountry"
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete country settings"
          openModal
          closeModal={handleDeleteModalClose}
          footerContent={getDeleteModalFooter()}
        >
          <Typography variant="h3" sx={{ marginBottom: 3 }}>
            {`You are about to delete all settings for "${tabToDelete}"`}
          </Typography>
          <Typography variant="body1">
            This action can not be undone. Are you sure you want to proceed?
          </Typography>
        </Modal>
      )}
    </Tabs>
  );
}

TabsContainer.defaultProps = {
  displayComponent: null,
};

TabsContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  displayComponent: PropTypes.node,
};

export default TabsContainer;
