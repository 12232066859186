import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oauth2-pkce';
import { mainNavigationContext } from '../../store/mainNavigation';
import { mainDataContext, reducers as dataReducers } from '../../store/mainData';
import { schemaContext, reducers as schemaReducers } from '../../store/schema';

import { getValidatedJson } from '../../services/flight-search-service';
import { TableBasedForm } from '../TableBasedForm';
import { Content } from '../CountryBasedForm';
import { CleanForm } from '../CleanForm';
import { spinnerContext } from '../../store/spinner';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import modalReducers from '../../store/modal/modal-reducers';
import { modalContext } from '../../store/modal';

function FormHandler() {
  const dispatch = useContext(mainDataContext)[1];
  const dispatchSchema = useContext(schemaContext)[1];
  const [state] = useContext(mainNavigationContext);
  const [payload, setPayload] = useState(null);
  const dispatchSpinner = useContext(spinnerContext)[1];
  const { authService } = useAuth();
  const logout = async () => authService.logout();
  const authToken = authService.getAuthTokens();
  const getRootPropertyName = () => Object.keys(payload.data)[0];
  const getRootProperty = () => payload.data[getRootPropertyName()];

  const isCountrySpecific = () => '*' in getRootProperty();
  const isTableBased = () => Array.isArray(payload.data[getRootPropertyName()]);
  const dispatchModal = useContext(modalContext)[1];

  function saveMainData(data) {
    setTimeout(() => {
      setPayload(data);
      dispatchModal({
        type: modalReducers.SHOW_MODAL,
        payload: { show: false },
      });
      dispatchSpinner({
        type: spinnerReducers.SHOW_SPINNER,
        payload: { show: false },
      });
      dispatchSchema({
        type: schemaReducers.SET_SCHEMA,
        payload: data.schema,
      });
      dispatch({
        type: dataReducers.SET_MAIN_DATA,
        payload: data.data,
      });
    }, 100);
  }

  useEffect(() => {
    if (state.selected === null) return;
    const loadData = async () => {
      dispatchSpinner({
        type: spinnerReducers.SHOW_SPINNER,
        payload: { show: true },
      });
      // API CALL
      const tokenExpired = new Date() > new Date(authToken.expires_at);
      if (tokenExpired) {
        setTimeout(() => {
          logout();
          dispatchSpinner({
            type: spinnerReducers.SHOW_SPINNER,
            payload: { show: false },
          });
        }, 1);
      } else {
        saveMainData(await getValidatedJson(state.selected.getUrl, authToken));
      }
    };
    loadData();
  }, [state]);

  if (!state.selected || !payload) return null;

  if (Object.keys(payload.data).length === 0) {
    return <>No data retreived</>;
  }

  if (isCountrySpecific()) {
    return (
      <Content
        data={Object.values(payload.data)[0]}
        mainDatas={payload.data}
        dataKey={Object.keys(payload.data)[0]}
        schema={payload.schema}
      />
    );
  }
  if (isTableBased()) {
    return (
      <TableBasedForm
        data={payload.data}
        schema={payload.schema}
        dataKey={getRootPropertyName()}
      />
    );
  }

  return (
    <CleanForm
      data={payload.data}
      schema={payload.schema}
      dataKey={getRootPropertyName()}
    />
  );
}

export default FormHandler;
