import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Notification from './Notification';
import { usePrevious } from '../hooks';

// make it work also if no 'notification-root' is given, e.g. inside storybook
const notificationRoot = document.getElementById('notification-root') || document.body;

function NotificationContainer({
  display,
  heading,
  className,
  closeCallback,
  autoCloseDelay,
  children,
  type,
  isAutoClosing,
}) {
  const [isOpen, setIsOpen] = useState(display);
  const [autoClosingTimerId, setAutoClosingTimerId] = useState(0);
  const element = document.createElement('div');

  const handleClose = useCallback(() => {
    setIsOpen(false);
    clearTimeout(autoClosingTimerId);
    setAutoClosingTimerId(0);

    if (closeCallback) closeCallback();
  }, [closeCallback, autoClosingTimerId]);

  useEffect(() => {
    if (!isOpen) return;

    notificationRoot.appendChild(element);

    // eslint-disable-next-line
    return () => {
      notificationRoot.removeChild(element);
    };
  }, [element, isOpen]);

  useEffect(() => {
    if (!isAutoClosing || !isOpen || autoClosingTimerId !== 0) return;

    setAutoClosingTimerId(
      setTimeout(() => {
        handleClose();
      }, autoCloseDelay)
    );
  }, [autoCloseDelay, isAutoClosing, isOpen, autoClosingTimerId, handleClose]);

  const prevDisplay = usePrevious(display);
  useEffect(() => {
    if (display === prevDisplay) return;

    if (!display) {
      handleClose();

      return;
    }

    setIsOpen(true);
  }, [display, handleClose, prevDisplay]);

  if (isOpen) {
    return ReactDOM.createPortal(
      <Notification
        heading={heading}
        handleClose={handleClose}
        className={className}
        type={type}
        isAutoClosing={isAutoClosing}
      >
        {children}
      </Notification>,
      element
    );
  }

  return null;
}

NotificationContainer.defaultProps = {
  heading: null,
  className: null,
  closeCallback: null,
  autoCloseDelay: 5000,
  type: 'notice',
  isAutoClosing: false,
};

NotificationContainer.propTypes = {
  display: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  className: PropTypes.string,
  closeCallback: PropTypes.func,
  autoCloseDelay: PropTypes.number,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  isAutoClosing: PropTypes.bool,
};

export default NotificationContainer;
