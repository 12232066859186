import { createTheme } from '@mui/material/styles';
import { breakpoints } from './breakpoints';
import { colors } from './colors';

const theme = createTheme({
  breakpoints,
  palette: colors,
});

export const customTypography = {
  fontFamily: 'Italian-regular, -apple-system, ItalianPlate',
  fontWeight: '400',
  p: {
    fontFamily: 'Italian-regular, Arial, -apple-system, ItalianPlate',
    fontWeight: '400',
  },
  body1: {
    lineHeight: '1.6',
  },
  body2: {
    fontSize: '13px',
  },
  h1: {
    fontFamily: 'ItalianBlackItalic, Arial, -apple-system, ItalianPlate',
    fontSize: '42px',
    [theme.breakpoints.up('md')]: {
      fontSize: '65px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '80px',
    },
  },
  h2: {
    fontFamily: 'ItalianBlackItalic, Arial, -apple-system, ItalianPlate',
    fontSize: '32px',
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '48px',
    },
  },
  h3: {
    fontFamily: 'ItalianBlackItalic, Arial, -apple-system, ItalianPlate',
    fontSize: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '34px',
    },
  },
  h4: {
    fontSize: '24px',
    fontWeight: '600',
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '34px',
    },
  },
  h5: {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px',
    },
  },
  h6: {
    fontSize: '16px',
    fontWeight: '600',
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: '600',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '18px',
    },
  },
  error: {
    color: theme.palette.primary.main,
    fontFamily: 'Italian-regular, -apple-system, ItalianPlate',
    fontWeight: '400',
    fontSize: theme.spacing(1.5),
    lineHeight: '1.66',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: theme.spacing(2),
    marginBottom: '0',
    marginLeft: theme.spacing(1),
  },
};
