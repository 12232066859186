import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Form from '@rjsf/material-ui/dist/v5';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import './editor.scss';
import widgets from './widgets';
import fields from './fields';
import { spinnerContext } from '../../store/spinner';

function Editor({
  className,
  uiSchema,
  formContext,
  rowChange,
  initialData,
  selectedSchema,
  handleSubmitForm,
}) {
  const spinnerDispatch = useContext(spinnerContext)[1];

  const handleSubmit = ({ formData }) => {
    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });
    handleSubmitForm(formData, rowChange);
  };
  delete selectedSchema.title;
  return (
    <Form
      className={className}
      schema={selectedSchema}
      formData={initialData}
      onSubmit={handleSubmit}
      uiSchema={uiSchema}
      formContext={formContext}
      widgets={widgets}
      fields={fields}
      showErrorList
    />
  );
}

Editor.defaultProps = {
  className: null,
  uiSchema: null,
  rowChange: null,
  initialData: {},
  selectedSchema: {},
  handleSubmitForm: null,
};

Editor.propTypes = {
  // eslint-disable-next-line react/require-default-props
  handleSubmitForm: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rowChange: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSchema: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object,
  className: PropTypes.string,
  uiSchema: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  formContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const MemoizedEditor = React.memo(Editor);
export default MemoizedEditor;
