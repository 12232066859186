/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clone from '../../utils/clone';
import { useDisplay, useCompactView, useDisplayToggle } from '../hooks';
import MemoizedEditor from '../Editor/Editor';
import { Toggle } from '../Toggle';
import { Switch } from '../Switch';
import Content from './Content';
import uiSchema from '../Editor/ui-schema';
import { mainNavigationContext } from '../../store/mainNavigation';
import { saveQueueContext } from '../../store/saveQueue';
import reducers from '../../store/saveQueue/save-queue-reducers';
import { mainDataContext } from '../../store/mainData';
import stripEmptyNestedObjects from '../../utils/strip-empty-nested-objects';

function CountryBasedFormContainer({ data, schema, dataKey, mainDatas }) {
  const [activeTab, setActiveTab] = useState('*');
  const [countryData, setCountryData] = useState(null);
  const { compactView, setCompactView } = useCompactView();
  const { displayEditMode, setDisplayEditMode } = useDisplayToggle(true);
  const tabs = Object.keys(data);
  const schemaRoot = schema.properties[dataKey].additionalProperties;
  const { getDisplayComponent } = useDisplay(data[activeTab], schemaRoot, '');
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);
  const title = Object.keys(mainDatas)[0];
  const [mainData] = useContext(mainDataContext);

  useEffect(() => {
    setCountryData(data[activeTab]);
  }, [activeTab, data]);

  const handleTabsClick = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const handleSubmit = (formData) => {
    setCountryData(formData);

    const nextData = { ...mainDatas, [title]: { ...data, [activeTab]: formData } };

    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: stripEmptyNestedObjects(nextData),
        rowChange: { type: 'editRule' },
      },
    });
  };

  function getEditor() {
    const clonedData = clone(data);
    const currentData = clonedData[activeTab];

    return (
      <>
        {schema && mainData !== {} && (
          <>
            <Toggle
              className="toggle--left"
              toggle={setCompactView}
              isOn={compactView}
              label="compact view"
            >
              <Switch />
            </Toggle>
            <MemoizedEditor
              className={`${compactView ? 'editor--compact' : null} ${dataKey}-editor`}
              selectedSchema={schemaRoot}
              initialData={countryData}
              uiSchema={uiSchema}
              formContext={{ currentData, path: [] }}
              handleSubmitForm={handleSubmit}
            />
          </>
        )}
      </>
    );
  }

  function getDisplay() {
    if (displayEditMode) return getEditor();
    return getDisplayComponent();
  }

  return (
    <>
      <Content
        overrideData={activeTab !== '*' ? countryData : null}
        schema={schemaRoot}
        tabs={tabs}
        tabClickCallBack={handleTabsClick}
        handleDisplayMode={setDisplayEditMode}
        displayMode={displayEditMode}
        displayComponent={getDisplay()}
      />
    </>
  );
}

CountryBasedFormContainer.propTypes = {
  mainDatas: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  schema: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  dataKey: PropTypes.string.isRequired,
};

export default CountryBasedFormContainer;
