import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function Switch({ checked, handleChange }) {
  return (
    <label className="switch">
      <input
        className="switch__input"
        type="checkbox"
        onChange={handleChange}
        checked={checked}
      />
      <span className="switch__slider" />
    </label>
  );
}

Switch.defaultProps = {
  checked: false,
  handleChange: () =>
    console.warn('you should add the prop "handleChange" to Switch component'), // eslint-disable-line no-console
};

Switch.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Switch;
