/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Box, Typography } from '@mui/material';
import { Select } from '../../Select';

function EnumWidget(props) {
  const { options, value, onChange, multiple } = props;
  const [formattedValues, setFormattedValues] = useState();

  const handleChange = (currValues) => {
    if (isArray(currValues)) {
      let values = [];
      values = currValues.reduce((acc, val) => [...acc, val.value], []);

      values = values.map((el) => el.trim());

      onChange(values);
    } else {
      onChange(currValues.value);
    }
  };

  useEffect(() => {
    if (!value || value.length < 1) {
      setFormattedValues(null);
      return;
    }

    const enums = isArray(value) ? value : [value];
    const formatted = options.enumOptions.map((option) =>
      enums.includes(option.value) ? option : null
    );

    setFormattedValues(formatted);
  }, [value]);

  const formattedOptions = options.enumOptions.map((obj) => {
    delete obj.schema;
    return obj;
  });

  const isMultiple = multiple ? { isMulti: true } : { isMulti: false };
  return (
    <Box data-testid="enumWidget" className="enum-widget">
      <Typography variant="h5" className="enum-header">
        {props.schema.title}
      </Typography>
      <Select
        {...props}
        options={formattedOptions}
        value={formattedValues}
        name="enum"
        onChange={handleChange}
        {...isMultiple}
      />
    </Box>
  );
}

EnumWidget.propTypes = {
  options: PropTypes.shape({
    enumOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
};

export default EnumWidget;
