export const colors = {
  primary: {
    main: '#ff3c00',
  },
  secondary: { main: '#004B99' },
  error: {
    main: '#ff3c00',
  },
  common: {
    main: {
      white: '#FFFFFF',
    },
  },
};
