import arraysMatch from './arrays-match';
import isObject from './is-object';

const getChangedValues = (oldObj, newObj, rowChange) => {
  if (rowChange?.type === 'editRule') return newObj;
  const mergedObj = {};
  const isArrayButNotIdentical = (a1, a2) => Array.isArray(a1) && !arraysMatch(a1, a2);

  Object.keys(oldObj).forEach((key) => {
    const oldVal = oldObj[key];
    const newVal = [...newObj[key]];
    if (isObject(oldVal)) {
      mergedObj[key] = getChangedValues(oldVal, newVal);
      return;
    }

    if (isArrayButNotIdentical(oldVal, newVal)) {
      mergedObj[key] = newVal.map((k) => k);
      return;
    }

    if (oldVal !== newVal && !Array.isArray(oldVal)) mergedObj[key] = newVal;
  });
  return mergedObj;
};

export default getChangedValues;
