import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '../Tabs';
import { Box } from '@mui/material';

function Content({ tabs, tabClickCallBack, displayComponent }) {
  return (
    <Box className="app__content container-fluid">
      <Box className="content--full-width">
        <Tabs
          tabs={tabs}
          onClick={tabClickCallBack}
          displayComponent={displayComponent}
        />
      </Box>
    </Box>
  );
}

Content.defaultProps = {
  displayComponent: null,
};

Content.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabClickCallBack: PropTypes.func.isRequired,
  displayComponent: PropTypes.node,
};

export default Content;
