import React from 'react';
import PropTypes from 'prop-types';
import './Notification.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

function Notification({
  heading,
  type,
  className,
  handleClose,
  isAutoClosing,
  children,
}) {
  return (
    <Box className={`notification notification--${type} ${className}`}>
      <Box className="notification__header">
        {heading && (
          <Typography variant="h4" className="notification__heading">
            {heading}
          </Typography>
        )}
        {!isAutoClosing && (
          <Box
            className="notification__close"
            onClick={handleClose}
            onKeyPress={handleClose}
            role="button"
            data-testid="close"
            tabIndex={0}
          >
            <CloseIcon />
          </Box>
        )}
      </Box>
      <Box className="notification__content">{children}</Box>
    </Box>
  );
}

Notification.defaultProps = {
  heading: null,
  className: null,
  isAutoClosing: false,
};

Notification.propTypes = {
  heading: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isAutoClosing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Notification;
