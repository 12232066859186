import { createTheme } from '@mui/material/styles';
import { colors } from '../colors';

const stylesSimple = {
  overflow: 'visible',
  textTransform: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  fontFamily: 'Italian',
  letterSpacing: 'initial',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: 'unset',
    '&:after': {
      transition: 'inherit',
      top: '50%',
      height: '100%',
      minHeight: '30px',
      transform: 'translateY(-50%)',
      left: '-16px',
      right: '-16px',
      position: 'absolute',
      content: '""',
      backgroundColor: '#0000000A',
    },
  },
  '& .MuiTouchRipple-root': {
    left: '-10px',
    right: '-10px',
    height: '100%',
  },
};

const ButtonOverrides = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '.75rem',
          padding: '.5rem .875rem',
        },
        sizeMedium: {
          fontSize: '1rem',
          padding: '.75rem 1.25rem',
        },
        sizeLarge: {
          fontSize: '1.25rem',
          padding: '1.25rem 2.75rem',
        },
        root: {
          borderRadius: '0px',
          lineHeight: '1rem',
          fontFamily: 'ItalianBlackItalic, Arial, Helvetica Neue',
          letterSpacing: '1px',
        },
      },
      defaultProps: {
        disableElevation: false,
      },
      variants: [
        {
          props: { variant: 'outlinedWhite', color: 'primary' },
          style: {
            border: `1px solid ${colors.common.main.white}`,
            textTransform: 'uppercase',
            fontFamily: 'ItalianBlackItalic',
            letterSpacing: 'initial',
            color: colors.common.main.white,
            '&:hover': {
              backgroundColor: 'ffffff0A',
            },
          },
        },

        {
          props: { variant: 'simple' },
          style: stylesSimple,
        },
        {
          props: { variant: 'simple', color: 'primary' },
          style: {
            ...stylesSimple,
            color: colors.primary.main,
            '&:hover': {
              '&:after': {
                backgroundColor: `${colors.primary.main}0A`,
              },
            },
            overflow: 'visible',
            '& .MuiTouchRipple-root': {
              left: '-10px',
              right: '-10px',
            },
          },
        },
        {
          props: { variant: 'simpleOutlined', color: 'primary' },
          style: {
            border: `1px solid ${colors.primary.main}`,
            textTransform: 'none',
            fontFamily: 'Italian',
            letterSpacing: 'initial',
            fontWeight: '600',
            color: colors.primary.main,
            '&:hover': {
              backgroundColor: `${colors.primary.main}0A`,
            },
          },
        },
      ],
    },
  },
});

export default ButtonOverrides.components?.MuiButton;
