import { useState } from 'react';

function useDisplayToggle(isOn) {
  const [displayEditMode, setDisplayEditMode] = useState(isOn);

  const handleDisplayMode = () => {
    setDisplayEditMode((prevState) => !prevState);
  };

  return { displayEditMode, setDisplayEditMode: handleDisplayMode };
}

export default useDisplayToggle;
