import fieldInnerOrder from './FieldOrdering/field-inner-order';
import { orderColumns } from './FieldOrdering/field-ordering';

const generateUiSchema = (schema, dataKey) => {
  const nextUiSchema = {};
  nextUiSchema[dataKey] = {
    'ui:submitButtonOptions': {
      submitText: 'fdslfdsf',
    },
    'ui:options': {
      orderable: false,
      addable: false,
      removable: false,
    },
    items: {
      'ui:order': [],
    },
  };

  const defaultOrder = orderColumns.defaultOrder;
  const orderColumn = orderColumns[dataKey] ? orderColumns[dataKey] : [];

  const getRequiredFields = () => {
    const requiredFields = schema.properties[dataKey].items.required;
    return requiredFields || [];
  };

  let orderCombined = [ ...orderColumn,getRequiredFields(),...defaultOrder ];
  // remove duplicates
  orderCombined = [...new Set(orderCombined)];

  nextUiSchema[dataKey].items['ui:order'] = orderCombined;

  const schemaItems = schema.properties[dataKey].items.properties;

  for (const property in schemaItems) {
    if (fieldInnerOrder.hasOwnProperty(property)) {
      nextUiSchema[dataKey].items[property] = {
        'ui:order': fieldInnerOrder[property],
      };
    }
  }

  if (!nextUiSchema[dataKey].items['ui:order'].includes('*')) {
    nextUiSchema[dataKey].items['ui:order'].push('*');
  }
  return nextUiSchema;
};

export default generateUiSchema;
