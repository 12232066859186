import clone from './clone';

export default function duplicateObjectRow(obj, dataKey, id) {
  const output = clone(obj);
  const foundIndex = obj[dataKey].findIndex((x) => x.id === id);
  const nextRow = obj[dataKey][foundIndex];
  delete nextRow.id;
  output[dataKey].splice(foundIndex, 0, nextRow);

  return output;
}
