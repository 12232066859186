// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { AuthProvider, AuthService, useAuth } from 'react-oauth2-pkce';
import { Button, Box } from '@mui/material';
import App from './App';
import './styles/login-page.scss';
import { Spinner } from './components/Spinner';

function WrappedSecuredApp() {
  const [authReady, setAuthReady] = useState(false);
  const [authService, setAuthService] = useState(null);

  async function defaultHttpLoader() {
    const response = await fetch('/config/config.json');
    return response.json();
  }

  useEffect(() => {
    if (!authReady) {
      defaultHttpLoader()
        .then((conf) => {
          setAuthService(
            new AuthService({
              clientId: conf.REACT_APP_CLIENT_ID,
              provider: conf.REACT_APP_PROVIDER,
              redirectUri: conf.REACT_APP_REDIRECT_URI,
              // @ts-ignore
              response_type: 'code',
              code_challenge:
                'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
              scopes: [],
            })
          );
        })
        .then(() => {
          setAuthReady(true);
        });
    }
  }, [authReady]);

  return (
    authReady && (
      <AuthProvider authService={authService}>
        <SecuredApp />
      </AuthProvider>
    )
  );
}

export const LogOutButton = () => {
  const { authService } = useAuth();
  const logout = async () => authService.logout();
  return (
    <Button
      size="small"
      onClick={logout}
      variant="outlinedWhite"
      color="primary"
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '16px',
      }}
    >
      Logout
    </Button>
  );
};

const SecuredApp = () => {
  const { authService } = useAuth();
  const login = async () => authService.authorize();

  if (authService.isPending()) {
    setTimeout(() => {
      authService.logout();
    }, 8000);

    return <Spinner />;
  }

  if (!authService.isAuthenticated()) {
    return (
      <Box className="login-page">
        <img className="login-page__logo" src="/kilroy-logo.svg" alt="Kilroy Logo" />
        <Button size="large" onClick={login} variant="outlinedWhite" color="primary">
          BEA Login
        </Button>
      </Box>
    );
  }
  return (
    <Box>
      <App />
    </Box>
  );
};

export { WrappedSecuredApp };
