import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import { MainNavigationProvider } from './store/mainNavigation';
import { MainDataProvider } from './store/mainData';
import { SchemaProvider } from './store/schema';
import { ModalProvider } from './store/modal';
import { SaveQueueProvider } from './store/saveQueue';

import { MainNavigation } from './components/MainNavigation';
import { FormHandler } from './components/FormHandler';
import { Save } from './components/Save';
import { Spinner } from './components/Spinner';
import './App.scss';
import startApm from './rum';
import { LogOutButton } from './SecuredApp';
import { Box } from '@mui/material';

function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'mt',
    seed: 'mt',
  });

  async function defaultHttpLoader() {
    const response = await fetch('/config/config.json');
    return response.json();
  }
  defaultHttpLoader().then((conf) => {
    startApm(conf);
  });

  return (
    <Box className="app">
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <ModalProvider>
            <header className="app__header">
              <img
                className="app__header__logo"
                src="/kilroy-logo.svg"
                alt="Kilroy Logo"
              />
              <Box>
                <LogOutButton />
              </Box>
            </header>
            <MainNavigationProvider>
              <MainNavigation />
              <SaveQueueProvider>
                <SchemaProvider>
                  <MainDataProvider>
                    <FormHandler />
                    <Save />
                  </MainDataProvider>
                </SchemaProvider>
              </SaveQueueProvider>
            </MainNavigationProvider>
            <Spinner />
          </ModalProvider>
        </BrowserRouter>
      </StylesProvider>
    </Box>
  );
}

export default App;
