import { createTheme } from '@mui/material/styles';
import { colors } from './colors';
import ButtonOverrides from './components/button';
import { InputOutlinedOverrides } from './components/inputOutlined';
import { customTypography } from './typography';
import { breakpoints } from './breakpoints';
import { CheckboxOverrides } from './components/checkbox';
import { PaperOverrides } from './components/paper';
import { DrawerOverrides } from './components/drawer';
import { FormHelperTextOverrides } from './components/formHelperText';
import { customShadows } from './customShadows';
import { InputTextOverrides } from './components/inputText';

const theme = createTheme({
  palette: colors,
  typography: customTypography,
  breakpoints,
  custom: {
    shadows: customShadows,
  },
  components: {
    MuiButton: ButtonOverrides,
    MuiCheckbox: CheckboxOverrides,
    MuiPaper: PaperOverrides,
    MuiOutlinedInput: InputOutlinedOverrides,
    MuiDrawer: DrawerOverrides,
    MuiFormHelperText: FormHelperTextOverrides,
    MuiTextField: InputTextOverrides,
    MuiGrid: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          '.MuiGrid-root': {
            marginTop: '8px',
            padding: '0',
            overflow: 'visible!important',
          },
        },
      },
    },
  },
});
export default theme;
