import isObject from './is-object';
import clone from './clone';

export default function deepMerge(target, source) {
  const output = clone(target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (!isObject(source[key])) {
        const index = output[key].findIndex((r) => r.id === source[key][0].id);
        output[key][index] = source[key][0];

        return;
      }

      output[key] = deepMerge(target[key], source[key]);
    });
  }

  return output;
}
