import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';
import './Select.scss';

// check out all available props from react-select at https://react-select.com/props#select-props
function Select({ className, name, options, ...props }) {
  const formattedOptions = options.map((obj) => {
    delete obj.schema;
    return obj;
  });

  return (
    <ReactSelect
      className={className ? `select ${className}` : 'select'}
      classNamePrefix="select"
      name={name}
      options={formattedOptions}
      {...props}
    />
  );
}

Select.defaultProps = {
  className: null,
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ).isRequired,
};

export default Select;
