import { useState } from 'react';
import { useCookies } from 'react-cookie';

function useCompactView() {
  const [cookies, setCookie, removeCookie] = useCookies(['compactView']);
  const [compactView, setCompactView] = useState(cookies.compactView === 'on');

  const handleCompactView = () => {
    compactView
      ? removeCookie('compactView', { path: '/' })
      : setCookie('compactView', 'on', { path: '/' });

    setCompactView(!compactView);
  };

  return { compactView, setCompactView: handleCompactView };
}

export default useCompactView;
