import React from 'react';
import {
  renderDeparturesArrivalsGeneric,
  renderFromToGeneric,
  renderMinMaxLengthGeneric,
  renderSurchargeIn,
  renderSplitArray,
  renderException,
  renderENUM,
  renderJSONarray,
  renderJSONobject,
  renderMaxPriceDifference,
  renderSplitArrayWithLineBreak,
  renderNestedObject,
  renderCheckBox,
  renderDaysOnDates,
  renderDepartureArrivalGeneric,
} from './customTableCellRenderings';

import {
  filterFromTo,
  filterMinMax,
  filterDeparturesArrivals,
  sortFromTo,
  sortMinMax,
  sortDeparturesArrivals,
  filterStringified,
} from './customTableFilters';

export const columnsTypes = [
  {
    // Active From To
    tooltip: 'Active From To',
    title: 'Active From To',
    field: 'activeFromToDates',
    render: (rowData) => renderFromToGeneric(rowData, 'activeFromToDates'),
    customFilterAndSearch: (term, rowData) =>
      filterFromTo(term, rowData, 'activeFromToDates'),
    customSort: (a, b) =>
      sortFromTo(a.activeFromToDates) - sortFromTo(b.activeFromToDates),
  },
  {
    // Airline
    tooltip: 'Airline',
    title: 'Airline',
    field: 'airline',
    name: 'airline',
    filtering: true,
  },
  {
    // Airlines
    tooltip: 'Airlines',
    title: 'Airlines',
    field: 'airlines',
    name: 'airlines',
    render: (rowData) => renderSplitArray(rowData, 'airlines'),
  },
  {
    // Airline Codes
    tooltip: 'Airline Codes',
    title: 'Airline Codes',
    field: 'airlineCodes',
    name: 'airlineCodes',
    render: (rowData) => renderSplitArray(rowData, 'airlineCodes'),
  },
  {
    // Airport
    tooltip: 'Airport',
    title: 'Airport',
    field: 'airport',
    name: 'airport',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'airport'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'airport'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.airport).localeCompare(sortDeparturesArrivals(b.airport)),
  },
  {
    // Airports
    tooltip: 'Airports',
    title: 'Airports',
    field: 'airports',
    name: 'airports',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'airports'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'airports'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.airports).localeCompare(
        sortDeparturesArrivals(b.airports)
      ),
  },
  {
    // Airports Exclusions Exclusions
    tooltip: 'Airports Exclusions',
    title: 'Airports Exclusions',
    field: 'airportsExclusions',
    name: 'airportsExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'airportsExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'airportsExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.airportsExclusions).localeCompare(
        sortDeparturesArrivals(b.airportsExclusions)
      ),
  },
  {
    // Area
    tooltip: 'Area',
    title: 'Area',
    field: 'area',
    name: 'area',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'area'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'area'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.area).localeCompare(sortDeparturesArrivals(b.area)),
  },
  {
    // Areas
    tooltip: 'Areas',
    title: 'Areas',
    field: 'areas',
    name: 'areas',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'areas'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'areas'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.areas).localeCompare(sortDeparturesArrivals(b.areas)),
  },
  {
    // Areas Exclusions
    tooltip: 'Areas Exclusions',
    title: 'Areas Exclusions',
    field: 'areasExclusions',
    name: 'areasExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'areasExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'areasExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.areasExclusions).localeCompare(
        sortDeparturesArrivals(b.areasExclusions)
      ),
  },
  {
    // Baggage Options
    tooltip: 'Baggage Options',
    title: 'Baggage Options',
    field: 'baggageOptions',
    name: 'baggageOptions',
    render: (rowData) => renderENUM(rowData, 'baggageOptions'),
  },
  {
    // Booked
    tooltip: 'Booked',
    title: 'Booked',
    field: 'booked',
    name: 'booked',
  },
  {
    // Booking Classes
    tooltip: 'Booking Classes',
    title: 'Booking Classes',
    field: 'bookingClasses',
    name: 'bookingClasses',
  },
  {
    // Cabin Classes
    tooltip: 'Cabin Classes',
    title: 'Cabin Classes',
    field: 'cabinClasses',
    name: 'cabinClasses',
    render: (rowData) => renderENUM(rowData, 'cabinClasses'),
  },
  {
    // Carry On
    tooltip: 'Carry On',
    title: 'Carry On',
    field: 'carryOn',
    name: 'carryOn',
    render: (rowData) => renderNestedObject(rowData, 'carryOn'),
    customFilterAndSearch: (term, rowData) => filterStringified(term, rowData, 'carryOn'),
  },
  {
    // Check In
    tooltip: 'Check In',
    title: 'Check In',
    field: 'checkIn',
    name: 'carrycheckInOcheckInn',
    render: (rowData) => renderNestedObject(rowData, 'checkIn'),
    customFilterAndSearch: (term, rowData) => filterStringified(term, rowData, 'checkIn'),
  },
  {
    // Cities
    tooltip: 'Cities',
    title: 'Cities',
    field: 'cities',
    name: 'cities',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'cities'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'cities'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.cities).localeCompare(sortDeparturesArrivals(b.cities)),
  },
  {
    // Cities Exclusions Exclusions
    tooltip: 'Cities Exclusions',
    title: 'Cities Exclusions',
    field: 'citiesExclusions',
    name: 'citiesExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'citiesExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'citiesExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.citiesExclusions).localeCompare(
        sortDeparturesArrivals(b.citiesExclusions)
      ),
  },
  {
    // City
    tooltip: 'City',
    title: 'City',
    field: 'city',
    name: 'city',
    render: (rowData) => renderDepartureArrivalGeneric(rowData, 'city'),
  },
  {
    // Code
    tooltip: 'Code',
    title: 'Code',
    field: 'code',
    name: 'code',
  },
  {
    // Countries
    tooltip: 'Countries',
    title: 'Countries',
    field: 'countries',
    name: 'countries',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'countries'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'countries'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.countries).localeCompare(
        sortDeparturesArrivals(b.countries)
      ),
  },
  {
    // Countries Exclusions
    tooltip: 'Countries Exclusions',
    title: 'Countries Exclusions',
    field: 'countriesExclusions',
    name: 'countriesExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'countriesExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'countriesExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.countriesExclusions).localeCompare(
        sortDeparturesArrivals(b.countriesExclusions)
      ),
  },
  {
    // Country
    tooltip: 'Country',
    title: 'Country',
    field: 'country',
    name: 'country',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'country'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'country'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.country).localeCompare(sortDeparturesArrivals(b.country)),
  },
  {
    // Country Settings
    render: (rowData) => renderJSONarray(rowData, 'countrySettings'),
    tooltip: 'Country Settings',
    title: 'Country Settings',
    field: 'countrySettings',
    name: 'countrySettings',
  },
  {
    // Days
    tooltip: 'Days',
    title: 'Days',
    field: 'days',
    name: 'days',
  },
  {
    // Days on dates
    tooltip: 'Days On Dates',
    title: 'Days On Dates',
    field: 'daysOnDates',
    name: 'daysOnDates',
    render: (rowData) => renderDaysOnDates(rowData, 'daysOnDates'),
  },
  {
    // Departure From to dates
    tooltip: 'Departure From To',
    title: 'Departure From To',
    field: 'departureFromToDates',
    name: 'departureFromToDates',
    render: (rowData) => renderFromToGeneric(rowData, 'departureFromToDates'),
    customFilterAndSearch: (term, rowData) =>
      filterFromTo(term, rowData, 'departureFromToDates'),
    customSort: (a, b) =>
      sortFromTo(a.departureFromToDates) - sortFromTo(b.departureFromToDates),
  },
  {
    // Description
    tooltip: 'Description',
    title: 'Description',
    field: 'description',
    name: 'description',
  },
  {
    // durationMinDays
    tooltip: 'Duration Min Days',
    title: 'Duration Min Days',
    field: 'durationMinDays',
    name: 'durationMinDays',
    // type: 'numeric',
  },
  {
    // Enabled
    tooltip: 'Enabled',
    title: 'Enabled',
    field: 'enabled',
    name: 'enabled',
  },
  {
    // encryptedUsers
    tooltip: 'Encrypted Users',
    title: 'Encrypted Users',
    field: 'encryptedUsers',
    name: 'encryptedUsers',
    // type: 'numeric',
  },
  {
    // euroPerKgCo2
    tooltip: 'Euro pr. Kg. CO2',
    title: 'Euro pr. Kg. CO2',
    field: 'euroPerKgCo2',
    name: 'euroPerKgCo2',
    // type: 'numeric',
  },
  {
    // Exclude types
    tooltip: 'Exclude types',
    title: 'Exclude types',
    field: 'excludeTypes',
    name: 'excludeTypes',
    render: (rowData) => renderENUM(rowData, 'excludeTypes'),
  },
  {
    // Exceptions
    tooltip: 'Exceptions',
    title: 'Exceptions',
    field: 'exceptions',
    name: 'exceptions',
    render: (rowData) => renderException(rowData, 'exceptions'),
  },
  {
    // Fare Basis
    tooltip: 'Fare Basis',
    title: 'Fare Basis',
    field: 'fareBasis',
    name: 'fareBasis',
  },
  {
    // 'First Name Constraints'
    tooltip: 'First Name Constraints',
    title: 'First Name Constraints',
    field: 'firstNameConstraints',
    name: 'firstNameConstraints',
    render: (rowData) => renderMinMaxLengthGeneric(rowData, 'firstNameConstraints'),
    customFilterAndSearch: (term, rowData) =>
      filterMinMax(term, rowData, 'firstNameConstraints'),
    customSort: (a, b) =>
      sortMinMax(a.firstNameConstraints) - sortMinMax(b.firstNameConstraints),
  },
  {
    // Flight Date
    tooltip: 'Flight Date',
    title: 'Flight Date',
    field: 'flightDate',
    name: 'flightDate',
    type: 'string',
  },
  {
    // Flight Numbers
    tooltip: 'Flight Numbers',
    title: 'Flight Numbers',
    field: 'flightNumbers',
    name: 'flightNumbers',
  },
  {
    // Id
    tooltip: 'Id',
    title: 'Id',
    field: 'id',
    name: 'id',
  },
  {
    // 'Last Name Constraints'
    tooltip: 'Last Name Constraints',
    title: 'Last Name Constraints',
    field: 'lastNameConstraints',
    name: 'lastNameConstraints',
    render: (rowData) => renderMinMaxLengthGeneric(rowData, 'lastNameConstraints'),
    customFilterAndSearch: (term, rowData) =>
      filterMinMax(term, rowData, 'lastNameConstraints'),
    customSort: (a, b) =>
      sortMinMax(a.lastNameConstraints) - sortMinMax(b.lastNameConstraints),
  },
  {
    // longHaul
    tooltip: 'Long Haul',
    title: 'Long Haul',
    field: 'longHaul',
    name: 'longHaul',
    render: (rowData) => renderNestedObject(rowData, 'longHaul'),
    customFilterAndSearch: (term, rowData) =>
      filterStringified(term, rowData, 'longHaul'),
  },
  {
    // Lower Case Normalized Character
    tooltip: 'Lower Case Normalized Character',
    title: 'Lower Case Normalized Character',
    field: 'lowerCaseNormalizedCharacter',
    name: 'lowerCaseNormalizedCharacter',
  },
  {
    // Lower Case Special Characters
    tooltip: 'Lower Case Special Characters',
    title: 'Lower Case Special Characters',
    field: 'lowerCaseSpecialCharacters',
    name: 'lowerCaseSpecialCharacters',
    render: (rowData) => renderSplitArray(rowData, 'lowerCaseSpecialCharacters'),
  },
  {
    // Name
    tooltip: 'Name',
    title: 'Name',
    field: 'name',
    name: 'name',
  },
  {
    // Upper Case Normalized Character
    tooltip: 'Upper Case Normalized Character',
    title: 'Upper Case Normalized Character',
    field: 'upperCaseNormalizedCharacter',
    name: 'upperCaseNormalizedCharacter',
  },
  {
    // Upper Case Special Characters
    tooltip: 'Upper Case Special Characters',
    title: 'Upper Case Special Characters',
    field: 'upperCaseSpecialCharacters',
    name: 'upperCaseSpecialCharacters',
    render: (rowData) => renderSplitArray(rowData, 'upperCaseSpecialCharacters'),
  },
  {
    // shortHaul
    field: 'shortHaul',
    name: 'shortHaul',
    render: (rowData) => renderNestedObject(rowData, 'shortHaul'),
    customFilterAndSearch: (term, rowData) =>
      filterStringified(term, rowData, 'shortHaul'),
  },
  {
    // Marketing Airlines
    tooltip: 'Marketing Airlines',
    title: 'Marketing Airlines',
    field: 'marketingAirlines',
    name: 'marketingAirlines',
  },
  {
    // Meta Partner Codes
    tooltip: 'Meta Partner Codes',
    title: 'Meta Partner Codes',
    field: 'metaPartnerCodes',
    name: 'metaPartnerCodes',
    render: (rowData) => renderSplitArray(rowData, 'metaPartnerCodes'),
  },
  {
    // Metasearch only
    tooltip: 'Meta Search Only',
    title: 'MSO',
    field: 'metaSearchOnly',
    name: 'metaSearchOnly',
    render: (rowData) => renderCheckBox(rowData, 'metaSearchOnly'),
  },
  {
    // Max Connection Time
    tooltip: 'Max Connection Time',
    title: 'Max Connection Time',
    field: 'maxConnectionTime',
    name: 'maxConnectionTime',
  },
  {
    // Max Discount
    tooltip: 'Max Discounts',
    title: 'Max Discounts',
    field: 'maxDiscounts',
    name: 'maxDiscounts',
    render: (rowData) => renderNestedObject(rowData, 'maxDiscounts'),
    customFilterAndSearch: (term, rowData) =>
      filterStringified(term, rowData, 'maxDiscounts'),
  },

  {
    // Max Price Difference
    tooltip: 'maxPriceDifference',
    title: 'Max Price Difference',
    field: 'maxPriceDifference',
    name: 'maxPriceDifference',
    render: (rowData) => renderMaxPriceDifference(rowData, 'maxPriceDifference'),
  },
  {
    // Meta Partner Name
    tooltip: 'Meta Partner Name',
    title: 'Meta Partner Name',
    field: 'metaPartnerName',
    name: 'metaPartnerName',
  },
  {
    // Min Total Profit
    tooltip: 'Min Total Profit',
    title: 'Min Total Profit',
    field: 'minTotalProfit',
    name: 'minTotalProfit',
  },
  {
    // Min Total Sales Price
    tooltip: 'Min Total Sales Price',
    title: 'Min Total Sales Price',
    field: 'minTotalSalesPrice',
    name: 'minTotalSalesPrice',
  },
  {
    // Min Total Segments
    tooltip: 'Min Total Segments',
    title: 'Min Total Segments',
    field: 'minTotalSegments',
    name: 'minTotalSegments',
  },
  {
    // No Max Discount
    tooltip: 'No Max Discount',
    title: 'No Max Discount',
    field: 'noMaxDiscount',
    name: 'noMaxDiscount',
    render: (rowData) => renderENUM(rowData, 'noMaxDiscount'),
  },
  {
    // Originals
    tooltip: 'Originals',
    title: 'Originals',
    field: 'originals',
    name: 'originals',
    render: (rowData) => renderSplitArray(rowData, 'originals'),
  },
  {
    // Paid
    tooltip: 'Paid',
    title: 'Paid',
    field: 'paid',
    name: 'paid',
  },
  {
    // Passenger Groups
    tooltip: 'Passenger Groups',
    title: 'Passenger Groups',
    field: 'passengerGroups',
    name: 'passengerGroups',
    render: (rowData) => renderENUM(rowData, 'passengerGroups'),
  },
  {
    // Passengers
    tooltip: 'Passengers',
    title: 'Passengers',
    field: 'passengers',
    name: 'passengers',
    render: (rowData) => renderFromToGeneric(rowData, 'passengers'),
    customFilterAndSearch: (term, rowData) => filterFromTo(term, rowData, 'passengers'),
  },
  {
    // Price Difference Filters
    tooltip: 'Price Difference Filters',
    title: 'Price Difference Filters',
    field: 'priceDifferenceFilters',
    name: 'priceDifferenceFilters',
    render: (rowData) => renderSplitArrayWithLineBreak(rowData, 'priceDifferenceFilters'),
  },
  {
    // Price Range
    tooltip: 'Price Range',
    title: 'Price Range',
    field: 'priceRange',
    name: 'priceRange',
    render: (rowData) => renderFromToGeneric(rowData, 'priceRange'),
  },
  {
    // Product Code
    tooltip: 'Product Code',
    title: 'Product Code',
    field: 'productCode',
    name: 'productCode',
  },
  {
    // Product Types
    tooltip: 'Product Types',
    title: 'Product Types',
    field: 'productTypes',
    name: 'productTypes',
    render: (rowData) => renderENUM(rowData, 'productTypes'),
  },
  {
    // Remark
    tooltip: 'Remark',
    title: 'Remark',
    field: 'remark',
    name: 'remark',
  },
  {
    // Replacement
    tooltip: 'Replacement',
    title: 'Replacement',
    field: 'replacement',
    name: 'replacement',
  },
  {
    // Replacements
    tooltip: 'Replacements',
    title: 'Replacements',
    field: 'replacements',
    name: 'replacements',
    render: (rowData) => renderSplitArray(rowData, 'replacements'),
  },
  {
    // Request type
    tooltip: 'Request type',
    title: 'Request type',
    field: 'requestType',
    name: 'requestType',
  },
  {
    // Surcharge
    tooltip: 'Surcharge',
    title: 'Surcharge',
    field: 'surcharge',
    name: 'surcharge',
    // type: "numeric",
  },
  {
    // Surcharge In
    tooltip: 'Surcharge In',
    title: 'Surcharge In',
    field: 'surchargeIn',
    name: 'surchargeIn',
    render: (rowData) => renderSurchargeIn(rowData, 'surchargeIn'),
  },
  {
    // surchargeLimit
    tooltip: 'Surcharge Limit',
    title: 'Surcharge Limit',
    field: 'surchargeLimit',
    name: 'surchargeLimit',
    // type: "numeric",
  },
  {
    // surchargeStart
    tooltip: 'Surcharge Start',
    title: 'Surcharge Start',
    field: 'surchargeStart',
    name: 'surchargeStart',
    // type: "numeric",
  },
  {
    // surchargeType
    tooltip: 'Surcharge Type',
    title: 'Surcharge Type',
    field: 'surchargeType',
    name: 'surchargeType',
  },
  {
    // ticketingAirlines
    tooltip: 'Ticketing Airlines',
    title: 'Ticketing Airlines',
    field: 'ticketingAirlines',
    name: 'ticketingAirlines',
  },
  {
    // Title
    tooltip: 'Title',
    title: 'Title',
    field: 'title',
    name: 'title',
  },
  {
    // Top level domains
    tooltip: 'Top level domains',
    title: 'Top level domains',
    field: 'topLevelDomains',
    name: 'topLevelDomains',
    render: (rowData) => renderSplitArray(rowData, 'topLevelDomains'),
  },
  {
    // tripTypes
    tooltip: 'TripTypes',
    title: 'TripTypes',
    field: 'tripTypes',
    name: 'tripTypes',
    render: (rowData) => renderENUM(rowData, 'tripTypes'),
  },
  {
    // Umbraco Id
    tooltip: 'Umbraco Id',
    title: 'Umbraco Id',
    field: 'umbracoId',
    name: 'umbracoId',
  },
];
